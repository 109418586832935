import {observer} from "mobx-react-lite";
import {stores} from "../../stores";
import {GLOBAL_MEMORY} from "../../stores/memoryStore";
import {ParameterType} from "../../model";
import {getGlobalParameterStateStyle, getPresetParameterStateStyle} from "../../utils/styling";

interface ParameterProps {
    parameter: ParameterType;
    presetNumber?: number;
}

export const InputCheckbox = observer(({parameter, presetNumber = GLOBAL_MEMORY}: ParameterProps) => {

    // console.log("ConfigurationParamInputCheckboxes:", h(stores.model.config[param.offset]));

    function handleChange(value: number) {
        const v = stores.memory.getValue(parameter, presetNumber);
        stores.memory.setValue(parameter, v ^ value, presetNumber);
        stores.midi.save(presetNumber);
    }

    function isChecked(value: number) {
        return !!(stores.memory.getValue(parameter, presetNumber) & value);
    }

    if (!parameter.values) {
        console.error("Missing values definition for parameter", parameter);
        return null;
    }

    const style = parameter.type === "global" ?
        getGlobalParameterStateStyle(parameter.id) :
        getPresetParameterStateStyle(parameter.id, presetNumber);

    // console.log("InputCheckbox render", presetNumber, parameter.id);

    return (
        <div className={`param-input input-checkbox state ${style}`}>
            {parameter.values.map((kv: any, _: number) => {
                const entry = Object.entries(kv);
                // @ts-ignore
                const value: number = parseInt(entry[0][0], 10);
                if (isNaN(value)) {
                    console.error("invalid configuration value for checkbox", parameter, entry)
                    return null;
                } else {
                    // @ts-ignore
                    const label: string = entry[0][1];
                    return (
                        <label key={value}>
                            <input type="checkbox" checked={isChecked(value)} onChange={() => handleChange(value)} />{label}
                        </label>
                    )
                }
            })}
        </div>
    );

});