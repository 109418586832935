import ReactMarkdown from "react-markdown";
import React from "react";
import {replacePlaceholders} from "../../utils/placeholders";

interface LabelProps {
    text: string|string[];
}

export const Label = ({text}: LabelProps) => {
    return (
        <div className="param-label mb-05rem md">
            <ReactMarkdown>{replacePlaceholders(typeof text === "string" ? text : text.join("\n\n"))}</ReactMarkdown>
        </div>
    );
}
