import {observer} from "mobx-react-lite";
import React, {useEffect} from "react";
import {stores} from "../stores";
import {ReadPresetButton} from "./ReadPresetButton";
import {ReadAllPresetsButton} from "./ReadAllPresetsButton";
import {Preset} from "./Preset";
import {SortableSelectorsList} from "./selectors/SortableSelectorsList";
import {Handle} from "./selectors/Handle";
import "./Presets.css";

export const Presets = observer(() => {

    stores.state.resetPresetSelectorsRefs();

    useEffect(() => {
        stores.state.selectPreset(stores.state.activePreset, true);
    }, []);

    return (
        <div className="presets main-content flex">
            <SortableSelectorsList />
            <div className="right grow pl-20 scroll-y">
                <Preset />
            </div>
            <div className="right max-w-30 pl-20">
                <div className="mt-20 line-125">
                    You can re-order the presets with the <Handle /> handle.
                </div>
                <div className="mt-5 line-125">
                    Hold the <em>Ctrl (Windows)</em> or <em>Cmd (Mac)</em> key to copy over instead of swapping.
                </div>
                <div className="mt-20 row">
                    <ReadAllPresetsButton />
                </div>
                <div className="mt-20">
                    <ReadPresetButton />
                </div>
            </div>
        </div>
    );

})
