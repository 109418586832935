
export const DA_SYSEX_MANUFACTURER_ID = [0x00, 0x02, 0x17];

export const SYSEX_ID_REQUEST = 'SYSEX_ID_REQUEST';
export const WRITE_SERIAL_NUMBER = 'WRITE_SERIAL_NUMBER';
export const WRITE_PRESET_NAME = 'WRITE_PRESET_NAME';
export const REQUEST_PRESET_NUMBER = 'REQUEST_PRESET_NUMBER';
export const DUMP_PRESET_NN = 'DUMP_PRESET_NN';
export const DUMP_PRESET_NN_RECALL = 'DUMP_PRESET_NN_RECALL';   // dump the preset and select it
export const STORE_PRESET_NN = 'STORE_PRESET_NN';
export const DUMP_CONFIGURATION = 'DUMP_CONFIGURATION';
export const STORE_CONFIGURATION = 'STORE_CONFIGURATION';
export const RESTART_DEVICE = 'RESTART_DEVICE';

export const SYSEX_COMMANDS: { [name: string]: number[] } = {
    [SYSEX_ID_REQUEST]:      [0x7E, 0x00, 0x06, 0x01],
    [WRITE_SERIAL_NUMBER]:   [0x57],
    [WRITE_PRESET_NAME]:     [0x58],
    [REQUEST_PRESET_NUMBER]: [0x79],
    [DUMP_PRESET_NN_RECALL]: [0x7A],
    [DUMP_PRESET_NN]:        [0x7C],
    [STORE_PRESET_NN]:       [0x7D],
    [DUMP_CONFIGURATION]:    [0x7E],
    [STORE_CONFIGURATION]:   [0x7F],
    [RESTART_DEVICE]: [0x5A, 0x4A, 0x3A],
}
