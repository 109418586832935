import {observer} from "mobx-react-lite";
import {stores} from "../stores";
import {sysex} from "../utils/midi";
import {DUMP_CONFIGURATION, SYSEX_COMMANDS} from "../model/commands";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import React from "react";

export const ReadConfigurationButton = observer(() => {

    function readGlobalConfiguration() {
        stores.midi.send(sysex(SYSEX_COMMANDS[DUMP_CONFIGURATION]));
    }

    return stores.midi.deviceFound ? (
        <div className="row baseline">
            <button type="button" onClick={readGlobalConfiguration}>Refresh values</button>
            <div data-tip data-for='info-refresh' className="ml-5"><FontAwesomeIcon icon={faQuestionCircle} /></div>
            <ReactTooltip id='info-refresh' type="info" effect="solid" place="right" >
                <div className="line-15">If you think the displayed values are not up to date, you can refresh them with this button.<br />
                    This will re-read the DMC.micro configuration.</div>
            </ReactTooltip>
        </div>
    ) : null;

});
