import React from "react";
import "./Help.css";

export const Help = () => {

    return (
        <div className="help main-content p-20" style={{overflow: 'auto'}}>
            <div className="title2">
                How to connect your micro.clock:
            </div>
            <div className="mt-10 line-125">
            Connect your micro.clock to your computer using a compatible USB cable
            </div>
            <div className="title2">
                How to use the editor:
            </div>
            <div className="mt-10 line-125">
                After connecting your device, give the app a few seconds to recognize the device and read its configuration.
            </div>
            <div className="mt-20 line-125">
                If the editor display the version and SN (serial number) of your device, everything's ok and you can proceed with the configuration.<br />
            </div>
            <div className="mt-5">
                Otherwise :
            </div>
            <ol>
                <li>refresh the editor window in your browser with Cmd-R (Mac) or F5 (Windows.)</li>
                <li>check the MIDI input and MIDI output selection.</li>
                <li>check your cable.</li>
            </ol>
            <div className="mt-20 line-125">
                Each time you modify a parameter, a yellow background will appear to show that the parameter's value has been edited.
                After a few seconds, the new value will be saved in the micro.clock and the yellow background will disappear.
            </div>
            <div className="mt-5 line-125">
                If a yellow background persists after you have changed a parameter, that means that the editor is unable to write the new value in the micro.clock.
                In that case please check the MIDI input/output selection, the cable, or refresh the browser window.
            </div>
            <div className="mt-5 line-125">
                Note: you can use the editor without a controller connected. In that case, after you have set up your configuration and your presets, use
                the Export feature to save your configuration in a file. You can re-import this file later on to update your device.
            </div>
            <div className="mt-10 line-125">
                Latest firmware, update tools and info are available on our <a href="https://www.disasterareadesigns.com/support">support page</a>.
            </div>
           <div className="title2">
                Need help?  Have feedback about the editor?
            </div>
            <div className="mt-10 line-125">
                Please contact us using the <a href="https://www.disasterareadesigns.com/contact">contact page</a>.
            </div>
            <div className="title2">
                Editor Changelog
            </div>
            <div className="mt-10 line-125">
                * 0.10.6 MC update to support micro.clock Gen4
            </div>
            <div className="mt-10 line-125">
                * 0.10.5 MC change BPM and division entry on preset editor tab
            </div>
            <div className="mt-10 line-125">
                * 0.10.4 MC fix issues with CV range reporting correctly
            </div>
            <div className="mt-10 line-125">
                * 0.10.3 MC fix Web MIDI error URL
            </div>
            <div className="mt-10 line-125">
                * 0.10.2 MC fix exported name reporting as DMC.micro, misc cleanup
            </div>
            <div className="mt-10 line-125">
                * 0.10.1 MC initial release
            </div>
        </div>
    );
}
