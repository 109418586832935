import {observer} from "mobx-react-lite";
import {ParameterType} from "../../model";
import {GLOBAL_MEMORY} from "../../stores/memoryStore";
import {stores} from "../../stores";
import ReactMarkdown from "react-markdown";
import "./Parameter.css";

interface ParameterProps {
    parameter: ParameterType;
    presetNumber?: number;
}

export const ParameterHelp = observer(({parameter, presetNumber = GLOBAL_MEMORY}: ParameterProps) => {

    if ("help" in parameter) {
        if ("value" in parameter.help) {
            if (stores.memory.getValue(parameter, presetNumber) === parameter.help.value) {
                return (
                    <div className="value-help mt-10 line-15">
                        <ReactMarkdown>
                            {typeof parameter.help.message === "string" ?
                                parameter.help.message :
                                parameter.help.message.join("\n\n")}
                        </ReactMarkdown>
                    </div>);
            }
        } else {
            console.warn("help condition invalid", parameter);
        }
    }

    return null;

});
