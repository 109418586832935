import {observer} from "mobx-react-lite";
import React from "react";
import {SortableSelectorsGrid} from "./selectors/SortableSelectorsGrid";

export const PresetsGrid = observer(() => {

    return (
        <div className="presets-grid main-content" style={{overflow: 'auto'}}>
            <SortableSelectorsGrid />
            {/*<SortableSelectorsGrid allPresets={true} />*/}
        </div>
    );

})