import React from "react";
import { observer } from "mobx-react-lite";
import { stores } from "../stores";
import { faCheck, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const validDeviceTypes = ["micro.clock", "micro.clock Gen4"];

export const DeviceInfo = observer(() => {
    const deviceType = stores.memory.getDeviceType();
    const isValidDeviceType = deviceType !== undefined && validDeviceTypes.includes(deviceType);

    const getErrorMessage = () => {
        if (!stores.midi.deviceFound) {
            return "Unable to detect the micro.clock. Check the MIDI input & output selections above and refresh the browser window.";
        } else if (deviceType === undefined) {
            return "Unable to determine device type. Please check your connection and try again.";
        } else {
            return `Invalid device type: ${deviceType}`;
        }
    };

    return (
        <div className="row">
            {stores.midi.deviceFound && isValidDeviceType && (
                <div className="green">
                    <FontAwesomeIcon icon={faCheck} /> {deviceType} v{stores.memory.getDecodedVersion()}, SN {stores.memory.getSerial()}
                </div>
            )}
            {(!stores.midi.deviceFound || !isValidDeviceType) && (
                <div className="red">
                    <FontAwesomeIcon icon={faExclamationCircle} /> {getErrorMessage()}
                </div>
            )}
        </div>
    );
});