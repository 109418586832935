import React, {useEffect, useState} from 'react';
import {
    closestCenter,
    DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors
} from '@dnd-kit/core';
import {
    rectSwappingStrategy,
    SortableContext,
    sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import {OverlayItem} from "./OverlayItem";
import {stores} from "../../stores";
import {Handle} from "./Handle";
import {SortableSelectorWrapper} from "./SortableSelectorWrapper";
import "./SortableSelectors.css";
import {observer} from "mobx-react-lite";
import {ReadAllPresetsButton} from "../ReadAllPresetsButton";

export const SortableSelectorsGrid = observer((props) => {

    const [ctrlKeyPressed, setCtrlKeyPressed] = useState(false);

    function keyDown(e) {
        setCtrlKeyPressed(e.ctrlKey || e.metaKey);
    }
    function keyUp(e) {
        setCtrlKeyPressed(e.ctrlKey || e.metaKey);
    }

    const [activeId, setActiveId] = useState(null);
    const [overId, setOverId] = useState(null);

    const max = stores.state.showAllPresets ? 99 : stores.memory.getGlobalParamValue("MAX_PRESET");
    const it = [];
    for (let k=0; k <= max; k++) {
        it.push(`${k}`);
    }
    const [items, setItems] = useState(it);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const showAll = stores.state.showAllPresets;
    const maxPresets = stores.memory.getGlobalParamValue("MAX_PRESET");

    useEffect(() => {
        const max = showAll ? 99 : maxPresets;
        const it = [];
        for (let k=0; k <= max; k++) {
            it.push(`${k}`);
        }
        setItems(it);
    }, [showAll, maxPresets]);

    function handleDragStart(event) {
        const {active} = event;
        setActiveId(active.id);
    }

    function handleDragMove(event) {
    }

    function handleDragOver(event) {
        const {over} = event;
        setOverId(over.id);
    }

    function handleDragEnd(event) {
        const {active, over} = event;
        setOverId(null);
        if (active.id !== over.id) {

            // we need to swap the items for the visual animation
            const oldIndex = items.indexOf(active.id);
            const newIndex = items.indexOf(over.id);
            const tmp = items.slice();
            tmp[oldIndex] = items[newIndex];
            tmp[newIndex] = items[oldIndex];
            setItems(tmp);

            if (ctrlKeyPressed) {
                stores.memory.copyPreset(items.indexOf(active.id), items.indexOf(over.id));
            } else {
                stores.memory.swapPresets(items.indexOf(active.id), items.indexOf(over.id));
                stores.midi.save(items.indexOf(active.id));
            }
            stores.midi.save(items.indexOf(over.id));

            setCtrlKeyPressed(false);
        }
    }

    // const strategy = rectSortingStrategy;
    const strategy = rectSwappingStrategy;

    let info = '';
    if (overId && activeId) {
        if (ctrlKeyPressed) {
            info = `Overwrite preset ${items.indexOf(overId)} with preset ${items.indexOf(activeId)}`;
        } else {
            info = `Swap presets ${items.indexOf(activeId)} and ${items.indexOf(overId)}`;
        }
    }

    const compact = stores.state.showAllPresets || (stores.memory.getGlobalParamValue("MAX_PRESET") > 65);

    return (
        <div className="px-20">
            <div className="float-right">
                <div className="flex">
                    {stores.state.showAllPresets && <ReadAllPresetsButton all={true} />}
                    <button onClick={() => stores.state.toggleShowAllPresets()} className="ml-10">
                        {stores.state.showAllPresets ? 'Show MAX_PRESET slots' : 'Show all 99 presets slots'}
                    </button>
                </div>
                <div className="mt-10">
                    {info}
                </div>
            </div>
            <div className="mt-20">
                Click the <Handle /> handle to move a preset.
            </div>
            <div className="mt-10">
                Hold the <em>Ctrl (Windows)</em> or <em>Cmd (Mac)</em> key to copy over instead of swapping.
            </div>
            <div onKeyDown={keyDown} onKeyUp={keyUp} className={`selectors-grid mt-20 ${compact ? 'compact' : ''}`}>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragMove={handleDragMove}
                    onDragOver={handleDragOver}
                    onDragEnd={handleDragEnd}>
                    <SortableContext
                        items={items}
                        strategy={strategy}>
                        {items.map((id, i) =>
                            <SortableSelectorWrapper
                                key={id}
                                presetNumber={i}
                                itemKey={id}
                                id={id}
                                overed={ctrlKeyPressed && id === overId}
                                srcElement={i === items.indexOf(overId)}
                                ctrlKeyPressed={ctrlKeyPressed} />
                        )}
                    </SortableContext>
                    <DragOverlay>
                        {activeId ? <OverlayItem id={activeId} num={items.indexOf(activeId)}/> : null}
                    </DragOverlay>
                </DndContext>
            </div>
            <div className="mt-10">
                {info}
            </div>
        </div>
    );

});
