import {StateStore} from "./stateStore";
import {MidiStore} from "./midiStore";
import {DebugStore} from "./debugStore";
import {MemoryStore} from "./memoryStore";

export class RootStore {
    state: StateStore;
    memory: MemoryStore;
    midi: MidiStore;
    debug: DebugStore;
    constructor() {
        // init order is important
        this.debug = new DebugStore(this);
        this.state = new StateStore(this);
        this.memory = new MemoryStore(this);
        this.midi = new MidiStore(this);
    }
}

export const stores = new RootStore();
