import React from "react";

interface DisplayProps {
    text: string;
}

export const Display = ({text}: DisplayProps) => {
    return (
        <div className="param-display mb-05rem">
            {text}
        </div>
    );
}
