import {observer} from "mobx-react-lite";
import React from "react";
import ReactMarkdown from "react-markdown";
import {GroupParameter} from "./GroupParameter";
import {conditionsOk} from "../utils/conditions";

interface GroupProps {
    group: any;
    groupKey: string;
}

export const Group = observer(({group, groupKey}: GroupProps) => {
    return (
        <div className={`global-group ${groupKey}`} id={group.label}>
            <div className="title">
                {group.label}
            </div>
            {group.info && <div className="group-info md">
                <ReactMarkdown>{group.info}</ReactMarkdown>
            </div>}
            {group.include.map((item: any, i: number) => {

                if (typeof item === "object") {
                    if (!conditionsOk(item)) {
                        return null;
                    }
                    if ("class" in item) {
                        return (
                            <div className={item.class} key={i}>
                                {item.title && <div className="title2">{item.title}</div>}
                                {item.params.map((p: any, j: number) => {
                                    return(
                                        <React.Fragment key={j}>
                                            <GroupParameter param={p} />
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        );
                    }
                }

                return (
                    <React.Fragment key={i}>
                        <GroupParameter param={item} />
                    </React.Fragment>
                );

            })}
        </div>
    );
});