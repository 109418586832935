
export function rangeEquals(source: Uint8Array, from: number, compare: number[]|Uint8Array): boolean {
    if (from >= source.length) return false;
    if ((from + compare.length) >= source.length) return false;
    for (let i=0; i<compare.length; i++) {
        if (source[from+i] !== compare[i]) return false;
    }
    return true;
}

export function string2bytes(str: string, length: number, fill = 0x00): number[] {
    let a = new Array(length);
    a.fill(fill);
    for (let i=0, strLen=str.length; i < strLen; i++) {
        let c = Math.min(str.charCodeAt(i));
        a[i] = c > 127 ? 0x00 : c;
        // a[i] = c > 127 ? 0x20 : c;
        if (i===7) break;   // max 8 chars
    }
    return a;
}

export function bytes2str(bytes: Uint8Array): string {
    let text = '';
    for (let i=0; i<bytes.length; i++) {
        text += String.fromCharCode(bytes[i]);
    }
    // console.log("bytes2str", bytes, text);
    return text;
}
