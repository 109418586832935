import React from "react";
import {observer} from "mobx-react-lite";
import GLOBAL_GROUPS from "../../model/global-groups.json";
import {groupEnabled} from "../../model";
import {OverviewGroup} from "./OverviewGroup";
import "./Overview.css";

export const Overview = observer(() => {

    return (
        <div className="overview">
            <div className="bg-warning mb-20 p-10">
                Work in progress...
            </div>
            <div className="right scroll-y grow Xoverview-container">
                <div>

                    {Object.values(GLOBAL_GROUPS).filter((group: any) => groupEnabled(group)).map((group, i) => {
                        return (
                            <React.Fragment key={i}>
                                <OverviewGroup group={group}/>
                            </React.Fragment>
                        );
                    })}


{/*
                    <div className="overview-group group-3cols">
                        <OverviewParameter parameterId="MAX_PRESET" />
                        <OverviewParameter parameterId="PRST_CHAN" />
                        <OverviewParameter parameterId="DEVICES" />
                    </div>
                    <div className="overview-group group-4cols">
                        <OverviewParameter parameterId="DEVICE_A" />
                        <OverviewParameter parameterId="DEVICE_B" />
                        <OverviewParameter parameterId="DEVICE_C" />
                        <OverviewParameter parameterId="DEVICE_D" />
                    </div>
                    <div className="overview-group group-4cols">
                        <OverviewParameter parameterId="NAME_DISP" />
                        <OverviewParameter parameterId="D_BRT" />
                    </div>
                    <div className="overview-group group-4cols">
                        <OverviewParameter parameterId="INPUT_CHAN" />
                        <OverviewParameter parameterId="MIDI_THRU" />
                        <OverviewParameter parameterId="USB_MODE" />
                    </div>
                    <div className="overview-group group-4cols">
                        <OverviewParameter parameterId="MULTIJACK" />
                        <OverviewParameter parameterId="JACK_CHAN" />
                    </div>
                    <div className="overview-group group-4cols">
                        <OverviewParameter parameterId="J_TIP_TYP" />
                        <OverviewParameter parameterId="J_TIP_NUM" />
                        <OverviewParameter parameterId="J_TIP_LOW" />
                        <OverviewParameter parameterId="J_TIP_HI" />
                        <OverviewParameter parameterId="J_TIP_CH" />
                    </div>
                    <div className="overview-group group-4cols">
                        <OverviewParameter parameterId="J_RNG_TYP" />
                        <OverviewParameter parameterId="J_RNG_NUM" />
                        <OverviewParameter parameterId="J_RNG_LOW" />
                        <OverviewParameter parameterId="J_RNG_HI" />
                        <OverviewParameter parameterId="J_RNG_CH" />
                    </div>
                    <div className="overview-group group-4cols">
                        <OverviewParameter parameterId="UTIL_LOAD" />
                        <OverviewParameter parameterId="UTIL_NAME" />
                    </div>
                    <div className="overview-group group-4cols">
                        <OverviewParameter parameterId="UTIL_MOD_X" />
                        <OverviewParameter parameterId="UT_X_L_TYP" />
                        <OverviewParameter parameterId="UT_X_L_NUM" />
                        <OverviewParameter parameterId="UT_X_L_LOW" />
                        <OverviewParameter parameterId="UT_X_L_HI" />
                        <OverviewParameter parameterId="UT_X_L_CH" />
                    </div>
                    <div className="overview-group group-4cols">
                        <OverviewParameter parameterId="UT_X_R_TYP" />
                        <OverviewParameter parameterId="UT_X_R_NUM" />
                        <OverviewParameter parameterId="UT_X_R_LOW" />
                        <OverviewParameter parameterId="UT_X_R_HI" />
                        <OverviewParameter parameterId="UT_X_R_CH" />
                    </div>
                    <div className="overview-group group-4cols">
                        <OverviewParameter parameterId="UT_X_A_TYP" />
                        <OverviewParameter parameterId="UT_X_A_NUM" />
                        <OverviewParameter parameterId="UT_X_A_LOW" />
                        <OverviewParameter parameterId="UT_X_A_HI" />
                        <OverviewParameter parameterId="UT_X_A_CH" />
                    </div>
                    <div className="overview-group group-4cols">
                        <OverviewParameter parameterId="UTIL_MOD_Y" />
                        <OverviewParameter parameterId="UT_Y_L_TYP" />
                        <OverviewParameter parameterId="UT_Y_L_NUM" />
                        <OverviewParameter parameterId="UT_Y_L_LOW" />
                        <OverviewParameter parameterId="UT_Y_L_HI" />
                        <OverviewParameter parameterId="UT_Y_L_CH" />
                    </div>
                    <div className="overview-group group-4cols">
                        <OverviewParameter parameterId="UT_Y_R_TYP" />
                        <OverviewParameter parameterId="UT_Y_R_NUM" />
                        <OverviewParameter parameterId="UT_Y_R_LOW" />
                        <OverviewParameter parameterId="UT_Y_R_HI" />
                        <OverviewParameter parameterId="UT_Y_R_CH" />
                    </div>
                    <div className="overview-group group-4cols">
                        <OverviewParameter parameterId="UT_Y_A_TYP" />
                        <OverviewParameter parameterId="UT_Y_A_NUM" />
                        <OverviewParameter parameterId="UT_Y_A_LOW" />
                        <OverviewParameter parameterId="UT_Y_A_HI" />
                        <OverviewParameter parameterId="UT_Y_A_CH" />
                    </div>
                    <div className="overview-group group-4cols">
                        <OverviewParameter parameterId="CUST_1_MIN" />
                        <OverviewParameter parameterId="CUST_1_MAX" />
                        <OverviewParameter parameterId="CUST_1_BYP" />
                        <OverviewParameter parameterId="CUST_1_TAP" />
                        <OverviewParameter parameterId="CUST_1_EXP" />
                        <OverviewParameter parameterId="CUST_1_DSP" />
                    </div>
                    <div className="overview-group group-4cols">
                        <OverviewParameter parameterId="CUST_2_MIN" />
                        <OverviewParameter parameterId="CUST_2_MAX" />
                        <OverviewParameter parameterId="CUST_2_BYP" />
                        <OverviewParameter parameterId="CUST_2_TAP" />
                        <OverviewParameter parameterId="CUST_2_EXP" />
                        <OverviewParameter parameterId="CUST_2_DSP" />
                    </div>
                    <div className="overview-group group-3cols">
                        <OverviewParameter parameterId="CLOCK_MODE" />
                        <OverviewParameter parameterId="TAP_LENGTH" />
                        <OverviewParameter parameterId="HOLD_TIME" />
                    </div>
*/}
                </div>
            </div>
        </div>
    );
});