import GP from "../model/global-params.json";
import PP from "../model/preset-params.json";
import {stores} from "../stores";

export interface ParameterType {
    id: string;
    type: string;
    display: string;
    showDisplay: boolean,
    label: string;
    showLabel: boolean,
    inputMode: "number"|"text"|"list"|"radio"|"checkbox";
    offset: number;
    mask: number;
    scale: number;
    divide: number;
    decimal: any;
    default: number;
    min: number;
    max: number;
    show: boolean;
    condition?: any;
    conditions?: any;
    help?: any;
    values?: any;
    MSB?: any;
    len?: number;   // length of text when inputMode=text
}

function normalizeParameter(param: any, id: string, type: string): ParameterType {
    //TODO: convert 0b... and 0x... numbers
    return {
        min: 0,
        max: 0x7F,
        default: 0,
        mask: 0x7F,
        showDisplay: true,
        showLabel: true,
        id,
        type,
        ...param};
}

export interface Parameters {
    [parameterId: string]: ParameterType;
}

// @ts-ignore
const GLOBAL: Parameters = Object.assign({}, GP);

Object.entries(GP).forEach(([k, v], _: number) => {
    GLOBAL[k] = normalizeParameter(v, k, "global");
});

export {GLOBAL};

// @ts-ignore
const PRESETS: Parameters = Object.assign({}, PP);

Object.entries(PP).forEach(([k, v], _: number) => {
    PRESETS[k] = normalizeParameter(v, k, "preset");
});

export {PRESETS};

export function getGlobalParameter(paramId: string): any {
    return GLOBAL[paramId];
}

export function getPresetParameter(paramId: string): any {
    return PRESETS[paramId] ?? null;
}

export function groupEnabled(group: any) {
    if ("display" in group) {
        if (!group.display) return false;
    }
    //FIXME: use condition function in utils
    if ("condition" in group) {
        // @ts-ignore
        if ("param" in group.condition && "value" in group.condition) {
            let condParam = getGlobalParameter(group.condition.param);
            if (condParam) {
                if (stores.memory.getValue(condParam) !== group.condition.value) {
                    return false;
                }
            } else {
                console.warn("condition param not found for group " + group.label)
            }
        } else {
            console.warn("invalid param condition for group " + group.label)
        }
    }
    return true;
}
