import {observer} from "mobx-react-lite";
import {stores} from "../stores";
import {sysex, wait, WAIT_BETWEEN_MESSAGES} from "../utils/midi";
import {DUMP_PRESET_NN, SYSEX_COMMANDS} from "../model/commands";
import {getGlobalParameter} from "../model";

interface ButtonProps {
    all?: boolean;
    maxPreset?: number;
    label?: string;
}

export const ReadAllPresetsButton = observer(({all, maxPreset, label}: ButtonProps) => {

    async function readAllPresets() {
        if (stores.midi.readingPreset > 0) return;
        const max = all ? 99 : (maxPreset ?? stores.memory.getValue(getGlobalParameter("MAX_PRESET")));
        for (let i=0; i<=max; i++) {
            // console.log(`read preset ${i}`);
            stores.midi.setReadingPreset(i);
            stores.midi.send(sysex([...SYSEX_COMMANDS[DUMP_PRESET_NN], i]));
            await wait(WAIT_BETWEEN_MESSAGES);
        }
        await wait(3 * WAIT_BETWEEN_MESSAGES);
        stores.midi.setReadingPreset(-1);   // reset counter
    }

    return stores.midi.deviceFound ? (
        <div className="row">
            {(stores.midi.readingPreset < 0) && <button type="button" onClick={readAllPresets} className="read-all-button">{label ?? "Read all presets"}</button>}
            {(stores.midi.readingPreset >= 0) && <button type="button" disabled={true} className="read-all-button">Reading {stores.midi.readingPreset}</button>}
            {/*{(stores.midi.readingPreset >= 0) && <div className="ml-10">{stores.midi.readingPreset}</div>}*/}
        </div>
    ) : null;

});
