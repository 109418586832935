import {action, makeAutoObservable} from 'mobx';
import {RootStore} from "./index";
import {DIRECTION_IN, DIRECTION_OUT, MidiMessage} from "../utils/midi";

const MAX_MESSAGES = 50;

export class DebugStore {

    stores: RootStore;

    messages: MidiMessage[] = [];
    // messagesOut: MidiMessage[] = [];

    constructor(stores: RootStore) {
        makeAutoObservable(this, {
            stores: false,
            addInMessage: action,
            addOutMessage: action
        });
        this.stores = stores;
    }

    addInMessage(message: WebMidi.MIDIMessageEvent) {
        let timestamp = Date.now();
        this.messages.unshift({
            direction: DIRECTION_IN,
            bytes: message.data, channel: 0, id: "", type: 0,
            timestamp: timestamp
        });
        if (this.messages.length > MAX_MESSAGES) this.messages.pop();
    }

    addOutMessage(message: Uint8Array) {
        let timestamp = Date.now();
        this.messages.unshift({
            direction: DIRECTION_OUT,
            bytes: message, channel: 0, id: "", type: 0,
            timestamp: timestamp
        });
        if (this.messages.length > MAX_MESSAGES) this.messages.pop();
    }

}
