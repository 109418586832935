import {observer} from "mobx-react-lite";
import React from "react";
import {GLOBAL_MEMORY} from "../../stores/memoryStore";
import {OverviewParameter} from "./OverviewParameter";

interface ConfigurationGroupProps {
    group: any;
}

export const OverviewGroup = observer(({group}: ConfigurationGroupProps) => {
    return (
        <div className="Xoverview-group Xpt-20">
            <div className="Xtitle">
                {group.label}
            </div>
{/*
            {!!group.info && <div className="group-info md">
                <ReactMarkdown>{group.info}</ReactMarkdown>
            </div>}
*/}
            <div className="overview-group">
                {group.include.map((param: any, i: number) => {

                    let parameterId;
                    let presetNumber = GLOBAL_MEMORY;
                    let paramRef;
                    let display = undefined;
                    let label = undefined;
                    if (typeof param === "object") {
                        paramRef = param.parameter;
                        display = param.display;
                        label = param.label;
                    } else {
                        paramRef = param;
                    }

                    if (paramRef.startsWith("global.")) {
                        parameterId = paramRef.substring(7);
                    } else if (paramRef.startsWith("preset.")) {
                        [presetNumber, parameterId] = paramRef.substring(7).split('.');
                    }

                    return (
                        <React.Fragment key={i}>
                            <OverviewParameter parameterId={parameterId} presetNumber={presetNumber} display={display} label={label} />
                        </React.Fragment>
                    );

                })}
            </div>
        </div>
    );
});