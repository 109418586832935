import {observer} from "mobx-react-lite";
import {stores} from "../stores";
import {sysex} from "../utils/midi";
import {DUMP_PRESET_NN, SYSEX_COMMANDS} from "../model/commands";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import React from "react";

export const ReadPresetButton = observer(() => {

    function readPreset() {
        stores.midi.send(sysex([...SYSEX_COMMANDS[DUMP_PRESET_NN], stores.state.activePreset]));
    }

    return stores.midi.deviceFound ? (
        <div className="row">
            <button type="button" onClick={readPreset}>Refresh values</button>
            <div data-tip data-for='info-refresh-preset' className="ml-5"><FontAwesomeIcon icon={faQuestionCircle} /></div>
            <ReactTooltip id='info-refresh-preset' type="info" effect="solid" place="top" >
                <div className="line-15">If you think the displayed values are not up to date, you can refresh them with this button.<br />
                    This will re-read the DMC.micro preset configuration.</div>
            </ReactTooltip>
        </div>
    ) : null;

});
