import {observer} from "mobx-react-lite";
import {getGlobalParameter, getPresetParameter} from "../../model";
import {GLOBAL_MEMORY} from "../../stores/memoryStore";
import {stores} from "../../stores";

interface ParameterProps {
    parameterId: string;
    presetNumber?: number;
    type?: string;  // override the JSON definition
    displayMapper?: (value: number) => string;
    display?: string;   // to override param definition
    label?: string;     // to override param definition
    // All other props
    [x:string]: any;
}

export const OverviewParameter = observer(({parameterId, presetNumber = GLOBAL_MEMORY, type, displayMapper = v => v.toString(), display, label, children, ...rest}: ParameterProps) => {

    // console.log("PARAMETER", parameterId, presetNumber, type, displayMapper, display, label, rest, JSON.stringify(rest));

    let parameter;
    if (presetNumber === GLOBAL_MEMORY) {
        parameter = getGlobalParameter(parameterId);
    } else {
        parameter = getPresetParameter(parameterId);
    }

    if (!parameter) {
        return <div>PARAMETER NOT FOUND</div>;
    }

    // const inputMode = type ?? parameter.inputMode.toLowerCase();

    return (
        <div className="Xparameter">
            {parameter.display}
            {/*{parameter.showLabel && <Label text={label ?? parameter.label} />}*/}
            <div className={`Xparam-value`}>
                {stores.memory.getValue(parameter)}
{/*
                {inputMode === "number" && <InputNumber parameter={parameter} presetNumber={presetNumber} />}
                {inputMode === "list" && <InputList parameter={parameter} presetNumber={presetNumber} {...rest} />}
                {inputMode === "checkbox" && <InputCheckbox parameter={parameter} presetNumber={presetNumber} />}
                {inputMode === "radio" && <InputRadio parameter={parameter} presetNumber={presetNumber} />}
                {inputMode === "text" && <InputText parameter={parameter} presetNumber={presetNumber} />}
*/}
            </div>
        </div>);

});
