import React from "react";
import {MidiPortsSelect} from "./midi/MidiPortsSelect";
import {DeviceInfo} from "./DeviceInfo";
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./Header.css";

export const Header = () => {

    return (
        <div className="header">
            <div className="logo">
                <a href="https://www.disasterareadesigns.com/" target="_blank" rel="noopener noreferrer">
                    <img src="DA_LOGO_WEB.png" alt="Disaster Area Designs logo"/>
                </a>
            </div>
            <div className="header-lines">
                <div className="header-top-line">
                    <div className="header-title">
                        DISASTER AREA - Configuration Editor
                    </div>
                    <div>
                        Editor v{process.env.REACT_APP_VERSION} by <a href="http://studiocode.dev/" target="_blank" rel="noopener noreferrer">StudioCode.dev</a>
                    </div>
                </div>
                <div className="row baseline between pt-10">
                    <MidiPortsSelect />
                    <div>
                        <a href="https://www.disasterareadesigns.com/support" target="_blank" rel="noopener noreferrer">Support <FontAwesomeIcon icon={faExternalLinkAlt} /></a>
                    </div>
                </div>
                <div className="row baseline between pt-10">
                    <DeviceInfo />
                </div>
            </div>
        </div>
    );

};
