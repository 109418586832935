import {observer} from "mobx-react-lite";
 import {stores} from "../stores";
 import {sysex} from "../utils/midi";
 import {RESTART_DEVICE, SYSEX_COMMANDS} from "../model/commands";
 import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
 import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
 import ReactTooltip from "react-tooltip";
 import React from "react";

 export const RestartDeviceButton = observer(() => {

     function restartDevice() {
         stores.midi.send(sysex(SYSEX_COMMANDS[RESTART_DEVICE]));
     }

     return stores.midi.deviceFound ? (
         <div className="row baseline">
             <button type="button" onClick={restartDevice}>Restart device</button>
             <div data-tip data-for='info-restart' className="ml-5"><FontAwesomeIcon icon={faQuestionCircle} /></div>
             <ReactTooltip id='info-restart' type="info" effect="solid" place="right" >
                 <div className="line-15">Restart device after hardware configuration changes.<br />
                     You may need to refresh the browser window after restart.</div>
             </ReactTooltip>
         </div>
     ) : null;

 });