import React from "react";
import {observer} from "mobx-react-lite";
import {stores} from "../stores";
import "./MidiFeedback.css";

export const MidiFeedback = observer(() => {

    return (
        <div className="midi-feedback row">
            {(!!stores.midi.receiving) && <div className="midi-reading ml-10">MIDI: read</div>}
            {(!!stores.midi.saving) && <div className="midi-reading ml-10">MIDI: write</div>}
        </div>
    );

    // return (
    //     <div className="midi-feedback row">
    //         {!!stores.midi.saving && <div className="midi-writing ml-10">SAVING</div>}
    //         {!!stores.midi.receiving && <div className="midi-reading ml-10">RECEIVING</div>}
    //     </div>
    // );

});
