import {observer} from "mobx-react-lite";
import {getGlobalParameter, getPresetParameter} from "../../model";
import {GLOBAL_MEMORY} from "../../stores/memoryStore";
import {InputNumber} from "./InputNumber";
import {InputList} from "./InputList";
import {InputRadio} from "./InputRadio";
import {InputCheckbox} from "./InputCheckbox";
import {Label} from "./Label";
import {Display} from "./Display";
import {InputText} from "./InputText";
import {ParameterHelp} from "./ParameterHelp";
import "./Parameter.css";
import {conditionsOk} from "../../utils/conditions";

interface ParameterProps {
    parameterId: string;
    presetNumber?: number;
    type?: string;  // override the JSON definition
    displayMapper?: (value: number) => string;
    display?: string;   // to override param definition
    label?: string;     // to override param definition
    className?: any;
    // All other props
    [x:string]: any;
}

export const Parameter = observer(({parameterId, presetNumber = GLOBAL_MEMORY, type, displayMapper = v => v.toString(), display, label, className, children, ...rest}: ParameterProps) => {

    // console.log("PARAMETER", parameterId, presetNumber, type, displayMapper, display, label, rest, JSON.stringify(rest));

    let parameter;
    if (presetNumber === GLOBAL_MEMORY) {
        parameter = getGlobalParameter(parameterId);
    } else {
        parameter = getPresetParameter(parameterId);
    }

    if (!parameter) {
        return <div>PARAMETER {parameterId} NOT FOUND</div>;
    }

    if (!conditionsOk(parameter)) {
        return null;
    }

    const inputMode = type ?? parameter.inputMode.toLowerCase();

    return (
        <div className={className ?? "parameter mb-15rem"}>
            {parameter.showDisplay && <Display text={display ?? parameter.display} />}
            {(label || parameter.showLabel) && <Label text={label ?? parameter.label} />}
            <div className={`param-value`}>
                {inputMode === "number" && <InputNumber parameter={parameter} presetNumber={presetNumber} />}
                {inputMode === "list" && <InputList parameter={parameter} presetNumber={presetNumber} {...rest} />}
                {inputMode === "checkbox" && <InputCheckbox parameter={parameter} presetNumber={presetNumber} />}
                {inputMode === "radio" && <InputRadio parameter={parameter} presetNumber={presetNumber} />}
                {inputMode === "text" && <InputText parameter={parameter} presetNumber={presetNumber} />}
            </div>
            <ParameterHelp parameter={parameter} presetNumber={presetNumber} />
            {children}
        </div>);

});
