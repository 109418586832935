import React from "react";
import {faBan, faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface LabelProps {
    label: string;
}

export const InputRadioLabel = ({label}: LabelProps) => {

    let icon = null;
    let text;

    const regex = /^(:(icon-check|icon-stop):)(.*)$/;
    const m = label.match(regex);

    if (m) {
        text = m[3];
        switch (m[2]) {
            case 'icon-check':
                icon = <FontAwesomeIcon icon={faCheck} />;
                break;
            case 'icon-stop':
                icon = <FontAwesomeIcon icon={faBan} className="icon-flip-horizontal" />
                break;
            default: break;
        }
    } else {
        text = label;
    }

    return (
        <>{icon}{text}</>
    );

};