import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {Handle} from "./Handle";
import {stores} from "../../stores";
import {getPresetParameter} from "../../model";
import {sysex} from "../../utils/midi";
import {DUMP_PRESET_NN_RECALL, SYSEX_COMMANDS} from "../../model/commands";
import {observer} from "mobx-react-lite";

export const SortableItem = observer((props) => {

    function selectPreset(number) {
        stores.state.selectPreset(number);
        stores.midi.send(sysex([...SYSEX_COMMANDS[DUMP_PRESET_NN_RECALL], number]));
    }

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        // transition,
        isDragging
    } = useSortable({id: props.id});

    const style = {
        transform: props.ctrlKeyPressed ? null : CSS.Transform.toString(transform),
        // transition,
        opacity: isDragging ? 0.5 : 1   //,
        // backgroundColor: props.overed ? '#FFAAAA' : ''
    };

    const presetNum = props.presetNumber;
    let tempoToDisplay = stores.memory.getValue(getPresetParameter("TEMPO"), presetNum);

    if (stores.memory.getGlobalParamValue("BPM_PRE") === 2) {
        tempoToDisplay = stores.memory.getValue(getPresetParameter("TEMPOMS"), presetNum);
        tempoToDisplay += " ms";
    }


    return (
        <div ref={setNodeRef} className={`preset-selector ${stores.state.activePreset === presetNum ? 'selected' : ''} ${props.srcElement ? 'src-elem' : ''} ${props.overed ? 'overed' : ''}`}
             key={presetNum} onClick={() => selectPreset(presetNum)} style={style} {...attributes}>
            {/*<div className="preset-number">{j}</div>*/}
            <div>
                <div className="preset-name">{stores.memory.getText(getPresetParameter("NAME"), presetNum)}</div>
                {/*<div className="preset-tempo">♩ {stores.memory.getValue(getPresetParameter("TEMPO"), presetNum)}</div>*/}
                <div className="preset-tempo">♩{tempoToDisplay}</div>
            </div>
            <div className="handle-wrapper" {...listeners}>
                <Handle />
            </div>
        </div>
    );

});
