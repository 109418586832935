import {observer} from "mobx-react-lite";
import React from "react";
import {Parameter} from "./parameter/Parameter";
import {GLOBAL_MEMORY} from "../stores/memoryStore";
import {stores} from "../stores";
import {getGlobalParameter} from "../model";

interface GroupProps {
    param: any;
    className?: any;
}

export const GroupParameter = observer(({param, className}: GroupProps) => {

    let parameterId;
    let presetNumber = GLOBAL_MEMORY;
    let paramRef;
    let display = undefined;
    let label = undefined;
    if (typeof param === "object") {
        paramRef = param.parameter;
        display = param.display;
        label = param.label;
        if ("condition" in param) {
            //TODO: allow condition on preset parameter too
            let condParam = getGlobalParameter(param.condition.param);
            if (condParam) {
                if (stores.memory.getValue(condParam) !== param.condition.value) {
                    return null;
                }
            } else {
                console.warn("condition param not found", param);
            }
        }
    } else {
        paramRef = param;
    }

    if (paramRef.startsWith("global.")) {
        parameterId = paramRef.substring(7);
    } else if (paramRef.startsWith("preset.")) {
        [presetNumber, parameterId] = paramRef.substring(7).split('.');
    }

    return (
        <Parameter parameterId={parameterId} presetNumber={presetNumber} display={display} label={label} />
    );

});