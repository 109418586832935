import React, {useState} from 'react';
import {
    closestCenter,
    DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors
} from '@dnd-kit/core';
import {
    rectSwappingStrategy,
    SortableContext,
    sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import {OverlayItem} from "./OverlayItem";
import {stores} from "../../stores";
import {SortableSelectorWrapper} from "./SortableSelectorWrapper";
import "./SortableSelectors.css";

export function SortableSelectorsList() {

    const [ctrlKeyPressed, setCtrlKeyPressed] = useState(false);

    function keyDown(e) {
        setCtrlKeyPressed(e.ctrlKey || e.metaKey);
    }
    function keyUp(e) {
        setCtrlKeyPressed(e.ctrlKey || e.metaKey);
    }

    const [activeId, setActiveId] = useState(null);
    const [overId, setOverId] = useState(null);

    const it = [];
    for (let k=0; k<=stores.memory.getGlobalParamValue("MAX_PRESET"); k++) {
        it.push(`${k}`);
    }
    const [items, setItems] = useState(it);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    function handleDragStart(event) {
        const {active} = event;
        setActiveId(active.id);
    }

    function handleDragMove(event) {
    }

    function handleDragOver(event) {
        const {over} = event;
        setOverId(over.id);
    }

    function handleDragEnd(event) {
        const {active, over} = event;
        setOverId(null);
        if (active.id !== over.id) {

            // we need to swap the items for the visual animation
            const oldIndex = items.indexOf(active.id);
            const newIndex = items.indexOf(over.id);
            const tmp = items.slice();
            tmp[oldIndex] = items[newIndex];
            tmp[newIndex] = items[oldIndex];
            setItems(tmp);

            if (ctrlKeyPressed) {
                stores.memory.copyPreset(items.indexOf(active.id), items.indexOf(over.id));
            } else {
                stores.memory.swapPresets(items.indexOf(active.id), items.indexOf(over.id));
                stores.midi.save(items.indexOf(active.id));
            }
            stores.midi.save(items.indexOf(over.id));

            setCtrlKeyPressed(false);
        }
    }

    // const strategy = verticalListSortingStrategy;
    const strategy = rectSwappingStrategy;

    return (
        <div onKeyDown={keyDown} onKeyUp={keyUp} className="presets-list" style={{overflow: 'auto'}}>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragMove={handleDragMove}
                onDragOver={handleDragOver}
                onDragEnd={handleDragEnd}>
                <SortableContext
                    items={items}
                    strategy={strategy}>
                    {items.map((id, i) =>
                            <SortableSelectorWrapper
                                presetNumber={i}
                                key={id}
                                id={id}
                                overed={ctrlKeyPressed && id === overId}
                                srcElement={i === items.indexOf(overId)}
                                ctrlKeyPressed={ctrlKeyPressed} />
                    )}
                </SortableContext>
                <DragOverlay>
                    {activeId ? <OverlayItem id={activeId} num={items.indexOf(activeId)} /> : null}
                </DragOverlay>
            </DndContext>
        </div>
    );

}
