import {DA_SYSEX_MANUFACTURER_ID} from "../model/commands";

export const SYSEX_START = 0xF0;
export const SYSEX_END = 0xF7;

export const DIRECTION_IN = 0;
export const DIRECTION_OUT = 1;

export type MessageDirection = 0 | 1;

export interface MidiMessage {
    id: string,
    direction: MessageDirection,
    channel: number,
    type: number,
    bytes: Uint8Array,
    timestamp: number
}

export function universalSysex(data: number[]): Uint8Array {
    //TODO: clamp the numbers to 0..255
    return new Uint8Array([
        SYSEX_START,
        ...data,
        SYSEX_END
    ]);
}


export function sysex(data: number[]): Uint8Array {
    //TODO: clamp the numbers to 0..255
    return new Uint8Array([
        SYSEX_START,
        ...DA_SYSEX_MANUFACTURER_ID,
        ...data,
        SYSEX_END
    ]);
}

export const WAIT_BETWEEN_MESSAGES = 100;

export const wait = (ms: number) => new Promise(r => setTimeout(r, ms));


