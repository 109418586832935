import {stores} from "../stores";
// import {getConfigurationParamById} from "../model";

//TODO: delete me
/*
export function configParamClass(paramID: string) {
    const param = getConfigurationParamById(paramID);
    if (param) {
        return `${stores.model.isConfigValueDirty(param.offset) ? 'dirty' : ''} ${stores.model.isConfigValueSaved(param.offset) ? 'saved' : ''}`;
    } else {
        console.warn("configParamClass: param not found", paramID)
        return '';
    }
}
*/

//TODO: delete me
/*
export function presetParamClass(index: number, paramID: string) {
    return `${stores.model.isPresetParamDirty(index, paramID) ? 'dirty' : ''} ${stores.model.isPresetParamSaved(index, paramID) ? 'saved' : ''}`;
}
*/


export function getGlobalParameterStateStyle(parameterId: string) {
    // console.log("globalParameterStateStyle", parameterId, stores.memory.globalStates[parameterId]);
    // const param = getConfigurationParamById(parameterId);
    // if (param) {
        return `${stores.memory.isGlobalParamDirty(parameterId) ? 'dirty' : ''} ${stores.memory.isGlobalParamSaved(parameterId) ? 'saved' : ''}`;
    // } else {
    //     console.warn("configParamClass: param not found", parameterId)
    //     return '';
    // }
}


export function getPresetParameterStateStyle(parameterId: string, presetNumber: number) {
    return `${stores.memory.isPresetParamDirty(parameterId, presetNumber) ? 'dirty' : ''} ${stores.memory.isPresetParamSaved(parameterId, presetNumber) ? 'saved' : ''}`;
}