import {observer} from "mobx-react-lite";
import {stores} from "../../stores";
import {GLOBAL_MEMORY} from "../../stores/memoryStore";
import {ParameterType} from "../../model";
import DEVICES from "../../model/devices.json";
import {getGlobalParameterStateStyle, getPresetParameterStateStyle} from "../../utils/styling";
import {ReactElement} from "react";

interface ParameterProps {
    parameter: ParameterType;
    presetNumber?: number;
    options?: ReactElement<any>[]|undefined;
    // All other props
    [x:string]: any;
}

export const InputList = observer(({parameter, presetNumber = GLOBAL_MEMORY, options, ...rest}: ParameterProps) => {

    function handleChange(e: React.FormEvent<HTMLSelectElement>) {
        stores.memory.setValue(parameter, parseInt(e.currentTarget.value, 10), presetNumber);
        stores.midi.save(presetNumber);
    }

    if (!parameter) {
        return <div>PARAMETER NOT FOUND</div>;
    }

    let opts: ReactElement<any>[];

    if (options) {
        opts = options;
    } else {

        if (!parameter.hasOwnProperty('values')) {

            opts = [...Array(parameter.max - parameter.min + 1)].map((i, j) => <option key={j} value={j}>{j}</option>);

        } else {

            if (typeof parameter.values === "string") {

                switch (parameter.values.toLowerCase()) {
                    case "devices.json":
                        const list = DEVICES
                            .map((device: any, i: number) => {
                                return {index: i, ...device}
                            })
                            .sort((a, b) => a.name.localeCompare(b.name));

                        opts = list.map((device: any, _: number) => <option value={device.index}
                                                                               key={device.index}>[{device.shortName.padEnd(4, ' ')}] {device.name}</option>);
                        break;
                    default:
                        console.warn("List: invalid list source:", parameter.values);
                        return null;
                }

            } else if (typeof parameter.values === "object") {

                opts = parameter.values.map((v: any, i: number) => <option value={i} key={i}>{v}</option>);

            } else {
                console.warn("List: invalid values type", parameter.values);
                return null;
            }
        }
    }

    const style = parameter.type === "global" ?
        getGlobalParameterStateStyle(parameter.id) :
        getPresetParameterStateStyle(parameter.id, presetNumber);

    return (
        <div className={`param-input input-list state ${style}`}>
            <select onChange={handleChange} value={stores.memory.getValue(parameter, presetNumber)}>
                {opts}
            </select>
        </div>
    );

});
