import React, {forwardRef} from 'react';
import {Handle} from "./Handle";
import {stores} from "../../stores";
import {getPresetParameter} from "../../model";

export const OverlayItem = forwardRef(({id, num, ...props}, ref) => {

    return (
        <div className={`preset-selector overlay`} {...props} ref={ref}>
            {/*<div className="preset-number">{num}</div>*/}
            <div>
                <div className="preset-name">{stores.memory.getText(getPresetParameter("NAME"), num)}</div>
                <div className="preset-tempo">♩ {stores.memory.getValue(getPresetParameter("TEMPO"), num)}</div>
            </div>
            <div className="handle-wrapper">
                <Handle />
            </div>
        </div>
    );

});
