import {SYSEX_END, SYSEX_START} from "./midi";

/**
 *
 * @param data Uint8Array
 */
export function isSysexData(data: Uint8Array) {
    if (data[0] !== SYSEX_START) return false;
    if (data[data.byteLength - 1] !== SYSEX_END) return false;
    return true;
}

/**
 * Split a dump into individual sysex messages
 * @param data
 * returns a array of sysex messages. The messages do not contains the sysex "start of sysex" 0xF0 and "end of sysex" 0xF7 bytes.
 */
export function splitDump(data: Uint8Array) {

    if (data === null) return [];

    let messages: Uint8Array[] = [];

    let i = 0;
    while (i < data.length) {

        i = data.indexOf(SYSEX_START, i);
        if (i < 0) break;

        let k = data.indexOf(SYSEX_END, i);
        if (k < 1) {
            console.warn("parseSysexDump: missing EOX, message ignored");
            break;
        }

        messages.push(data.slice(i, k + 1));  // data.slice(i, k) are the data between MANUFACTURER and SYSEX_END

        i = k+1;

    } // while

    return messages;
}
