import {stores} from "../stores";

export function replacePlaceholders(s: string): string {
    let r = s;
    let p = s.indexOf('%CHANNEL_A%');
    //FIXME: factorize this code
    if (p) {
        let v = 1 + stores.memory.getGlobalParamValue("CHAN_OFFSET");
        r = r.replaceAll('%CHANNEL_A%', v.toString(10))
    }
    p = s.indexOf('%CHANNEL_B%');
    if (p) {
        let v = 2 + stores.memory.getGlobalParamValue("CHAN_OFFSET");
        r = r.replaceAll('%CHANNEL_B%', v.toString(10))
    }
    p = s.indexOf('%CHANNEL_C%');
    if (p) {
        let v = 3 + stores.memory.getGlobalParamValue("CHAN_OFFSET");
        r = r.replaceAll('%CHANNEL_C%', v.toString(10))
    }
    p = s.indexOf('%CHANNEL_D%');
    if (p) {
        let v = 4 + stores.memory.getGlobalParamValue("CHAN_OFFSET");
        r = r.replaceAll('%CHANNEL_D%', v.toString(10))
    }
    return r;
}
