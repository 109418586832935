import {stores} from "../stores";
import {splitDump} from "./sysex";
import {hs} from "./hexstring";

const MAX_FILE_SIZE = 100 * 1024;

/**
 *
 * @param files
 * @returns {Promise<void>}
 */
export async function readFiles(files) {
    console.log("readFiles", files, typeof files);
    stores.state.clearImportedPresets();
    stores.state.clearImportedConfiguration();
    await Promise.all([...files].map(
        async file => {
            if (file.size > MAX_FILE_SIZE) {
                console.warn(`${file.name}: file too big, ${file.size}`);
            } else {
                const data = new Uint8Array(await new Response(file).arrayBuffer());
                for (let dump of splitDump(data)) {
                    console.log("import dump", hs(dump));
                    let info = stores.midi.parseSysex(dump, true);
                    console.log("imported dump of type", info);
                    switch(info) {
                        case "preset":
                            stores.state.incImportedPresets();
                            break;
                        case "configuration":
                            stores.state.incImportedConfiguration();
                            break;
                        default:
                            break;
                    }
                }
                // non sysex files are ignored
            }
            // too big files are ignored
        }
    ));
}
