import {action, makeAutoObservable} from 'mobx';
import {RootStore} from "./index";
import {loadPreferences, savePreferences} from "../utils/preferences";
import React from "react";

export type Tab = "presets" | "global" | "utils" | "custom1" | "custom2" | "lab" | "import-export" | "overview" | "presets-grid" | "help";

export class StateStore {

    stores: RootStore;

    activeTab: Tab;

    activePreset: number = 0;
    lastUserSelectPreset: number;   // hack to avoid having the selector being scrolled to the top when the user click the selector (and a PC is received because we read the preset)

    importedPresets: number;    // number of imported presets
    importedConfiguration: number;    // number of imported configuration

    showAllPresets: boolean = false;

    colors: boolean;

    presetSelectorsRefs: React.RefObject<any>[] = [];

    constructor(stores: RootStore) {
        makeAutoObservable(this, {
            stores: false,
            clearImportedPresets: action,
            incImportedPresets: action,
            clearImportedConfiguration: action,
            incImportedConfiguration: action,
            selectPreset: action,
            enableColors: action,
            disableColors: action,
            setShowAllPresets: action,
            toggleShowAllPresets: action,
            lastUserSelectPreset: false,
            presetSelectorsRefs: false
        });
        this.activeTab = "global";
        this.activePreset = 0;
        this.lastUserSelectPreset = -1;
        this.importedPresets = 0;
        this.importedConfiguration = 0;
        const prefs = loadPreferences();
        this.colors = prefs.colors;
        document.documentElement.setAttribute("colors", this.colors ? "on" : "off");
        this.stores = stores;
    }

    resetPresetSelectorsRefs() {
        // console.log("resetPresetSelectorsRefs");
        this.presetSelectorsRefs = [];
        for (let i=0; i <= 106; i++) {
            this.presetSelectorsRefs.push(React.createRef());
        }
    }

    setShowAllPresets(b: boolean) {
        this.showAllPresets = b;
    }

    toggleShowAllPresets() {
        this.showAllPresets = !this.showAllPresets;
    }

    setTab(tab: Tab): void {
        this.activeTab = tab;
    }

    selectPreset(preset: number, scrollIntoView = false): void {

        // console.log("selectPreset", preset, this.lastUserSelectPreset, scrollIntoView);

        this.activePreset = preset;
        if (scrollIntoView) {
            // console.log("selectPreset: scroll into view");
            // this.lastUserSelectPreset = -1;
            try {
                this.presetSelectorsRefs[preset].current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    // block: 'start'
                });
            } catch (e) {
                // console.log("presetSelectorsRefs not yet initialized");
            }
        } else {
            this.lastUserSelectPreset = preset;
        }
    }

    clearImportedPresets(): void {
        this.importedPresets = 0;
    }

    incImportedPresets(): void {
        this.importedPresets++;
    }

    clearImportedConfiguration(): void {
        this.importedConfiguration = 0;
    }

    incImportedConfiguration(): void {
        this.importedConfiguration++;
    }

    enableColors(): void {
        this.colors = true;
        document.documentElement.setAttribute("colors", "on");
        savePreferences({colors: this.colors});
    }

    disableColors(): void {
        this.colors = false;
        document.documentElement.setAttribute("colors", "off");
        savePreferences({colors: this.colors});
    }

    toggleColors(): void {
        this.colors = !this.colors;
        document.documentElement.setAttribute("colors", this.colors ? "on" : "off");
        savePreferences({colors: this.colors});
    }

}
