import React from "react";
import {observer} from "mobx-react-lite";
import {stores} from "../stores";
import "./Tabs.css";

export const Tabs = observer(() => {

    return (
        <div className="tabs">
            <div className="tab padding-left">
                &nbsp;&nbsp;
            </div>
            <div className={`tab ${stores.state.activeTab === 'global' ? 'active' : 'inactive'}`} onClick={() => stores.state.setTab("global")}>
                <div className="tab-name">
                    Configuration
                </div>
            </div>
            <div className="tab padding-middle">
                &nbsp;&nbsp;
            </div>
            <div className={`tab ${stores.state.activeTab === 'presets' ? 'active' : 'inactive'}`} onClick={() => stores.state.setTab("presets")}>
                <div className="tab-name">
                    Preset Editor
                </div>
            </div>
            <div className="tab padding-middle">
                &nbsp;&nbsp;
            </div>
            <div className={`tab ${stores.state.activeTab === 'presets-grid' ? 'active' : 'inactive'}`} onClick={() => stores.state.setTab("presets-grid")}>
                <div className="tab-name">
                    Preset Order
                </div>
            </div>
            <div className="tab padding-middle">
                &nbsp;&nbsp;
            </div>
            <div className={`tab ${stores.state.activeTab === 'import-export' ? 'active' : 'inactive'}`} onClick={() => stores.state.setTab("import-export")}>
                <div className="tab-name">
                    Import / Export
                </div>
            </div>
            <div className="tab padding-middle">
                &nbsp;&nbsp;
            </div>
            <div className={`tab ${stores.state.activeTab === 'help' ? 'active' : 'inactive'}`} onClick={() => stores.state.setTab("help")}>
                <div className="tab-name">
                    Help / About
                </div>
            </div>
{/*
            <div className="tab padding-middle">
                &nbsp;&nbsp;
            </div>
            <div className={`tab ${stores.state.activeTab === 'overview' ? 'active' : 'inactive'}`} onClick={() => stores.state.setTab("overview")}>
                <div className="tab-name">
                    Overview
                </div>
            </div>
*/}
{/*
            {(process.env.REACT_APP_ENV === "dev") &&
            <div className="tab padding-middle">
                &nbsp;&nbsp;
            </div>}
            {(process.env.REACT_APP_ENV === "dev") &&
            <div className={`tab ${stores.state.activeTab === 'lab' ? 'active' : 'inactive'}`} onClick={() => stores.state.setTab("lab")}>
                <div className="tab-name">
                Lab
                </div>
            </div>}
*/}
{/*
            {(stores.state.activeTab === 'presets' || stores.state.activeTab === 'global') &&
            <div className="tab padding tab-info light-text text-small">
                The DMC.micro is automatically read. Any change is automatically saved.
            </div>}
*/}
            <div className="tab padding">
                &nbsp;&nbsp;
            </div>
        </div>
    );

});
